import React from "react";
import MarketingPlanning from "../components/PortFolio/MarketingPlanning";
import Ourclients from "../components/PortFolio/Ourclients";
import SocialMediaGraphics from "../components/PortFolio/SocialMediaGraphics";
import Heading from "../components/PortFolio/Heading";
import OurResults from "../components/PortFolio/OurResults";
import WhyBambooRuts from "../components/PortFolio/WhyBambooRuts";
import HeroSection from "../components/PortFolio/HeroSection";

const Portfolio = () => {
  return (
    <div className="overflow-hidden">
      <HeroSection />
      <Ourclients />
      <Heading />
      <OurResults />
      <MarketingPlanning />
      <SocialMediaGraphics />
      <WhyBambooRuts />
    </div>
  );
};

export default Portfolio;
