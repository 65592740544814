import React from "react";
import { Container } from "react-bootstrap";
import statue from "../../assets/images/png/statue.png";

const WhyBambooRuts = () => {
  return (
    <div id="why-bamboo-ruts" className="">
      <Container className="position-relative statue-custom-margin">
        <div
          style={{
            background: "#ADADAD",
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%",
            top: "-50px",
          }}
          className="p-4 text-center position-absolute rounded-3"
        >
          <h3 className="ff_bungee mb-0 fs_70">Why Bamboo Ruts ?</h3>
          <p className="mb-0 ff_birthstone fs_48">we Design Your Growth.</p>

          <div className="d-flex align-items-center">
            <p className="mb-0 ff_birthstone fs_48">E-Mail-</p>{" "}
            <a
              className="ff_birthstone fs_48 text-black"
              href="mailto:info@bambooruts.com"
            >
              info@bambooruts.com
            </a>
          </div>
          <div className="d-flex align-items-center">
            <p className="mb-0 ff_birthstone fs_48">Ph.No.-</p>{" "}
            <a
              className="ff_birthstone fs_48 text-black"
              href="tel:+91 7300880028"
            >
              +91 7300880028
            </a>
          </div>
        </div>
      </Container>
      <img className="w-100 position-relative z-2" src={statue} alt="" />
    </div>
  );
};

export default WhyBambooRuts;
