import React from "react";
import marketingPlanning from "../../assets/images/png/marketingPlaning.png";
import { Container } from "react-bootstrap";

const MarketingPlanning = () => {
  return (
    <div className="py-5">
      <Container>
        <div>
          <img
            style={{ maxHeight: "681px" }}
            className="w-100 h-100 object-fit-contain"
            src={marketingPlanning}
            alt=""
          />
        </div>
      </Container>
    </div>
  );
};

export default MarketingPlanning;
