import React from "react";
import { Container } from "react-bootstrap";

const Heading = () => {
  return (
    <div className="py-4 mb-0">
      <Container>
        <h2 className="ff_gallient text-white text-center heading-text fs_98 pb-lg-5 mb-lg-5">
          CREATIVE DEVELOPMENT CONVERSION ENGAGEMENT
        </h2>
      </Container>
    </div>
  );
};

export default Heading;
