import React from "react";
import { Container } from "react-bootstrap";
import clientsLogo from "../../assets/images/png/clientsLogo.png";

const Ourclients = () => {
  return (
    <div className="py-5">
      <h2 className="mb-0 ff_gallient text-white heading-text text-center py-3 fs_70">
        OUR CLIENT
      </h2>
      <div className="our-client-bg py-3">
        <Container>
          <div>
            <img
              style={{ maxHeight: "150px" }}
              className="w-100 object-fit-contain"
              src={clientsLogo}
              alt=""
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Ourclients;
