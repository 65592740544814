import React, { useState } from "react";
import Slider from "react-slick";
import vedas from "../../assets/images/png/finVedas.png";
import solar from "../../assets/images/png/gautamSolar.png";
import dental from "../../assets/images/png/Dental.png";
import angular from "../../assets/images/png/angular.png";

const OurResults = () => {
  const [sliderData, setSliderData] = useState([
    {
      img: vedas,
    },
    {
      img: solar,
    },
    {
      img: dental,
    },
  ]);
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="px-1 py-5 bg-angular position-relative mt-5 mt-md-0">
      <h2 className="ff_gallient text-white text-center our-result-heading heading-text fs_58 ">
        OUR RESULTS
      </h2>
      <div>
        <Slider className="" {...settings}>
          {sliderData.map((value, index) => (
            <div className="px-2 py-5" key={index}>
              <img
                loading="lazy"
                className="w-100 company-img-shadow"
                src={value.img}
                alt="maxdent"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default OurResults;
