import React from "react";
import { Container } from "react-bootstrap";
import socialMediaGraphic from "../../assets/images/png/socialMediaGraphics.png";

const SocialMediaGraphics = () => {
  return (
    <div className="">
      <div className="">
        <h2 className="mb-0 ff_gallient text-white heading-text social-media-heading">
          Social Media Graphics
        </h2>
        <img
          style={{ maxHeight: "" }}
          className="w-100 position-relative z-1"
          src={socialMediaGraphic}
          alt=""
        />
      </div>
    </div>
  );
};

export default SocialMediaGraphics;
