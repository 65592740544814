import React from "react";
import AppNavbar from "../Navbar/AppNavbar";
import PortfolioNavbar from "./PortfolioNavbar";
import contactus from "../../assets/images/png/contactus.png";
import { Container } from "react-bootstrap";

const HeroSection = () => {
  return (
    <div className="position-relative bg-black">
      <a href="tel:+91 7300880028">
        <img
          className="w-100 position-absolute cursor_pointer z-2 contact-us-img"
          src={contactus}
          alt=""
        />
      </a>
      <PortfolioNavbar
        textWhite="text-white"
        wUnset="width-unset"
        opacity_100_work="opacity-100"
        opacity_70="opacity_70"
      />
      <Container>
        <div className="">
          <video
            className="w-100 h-100 object-fit-contain"
            src={"https://bambooruts.s3.ap-south-1.amazonaws.com/heroVid.mp4"}
            autoPlay
            loop
            muted
            playsInline
          />
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;
